import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { getAllProduct } from '../../utils/getAllProduct';
import axios from 'axios';
import './style.css';

const Admin = () => {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);  // For form submission errors
  const [newProduct, setNewProduct] = useState({
    authorId:1,
    name: '',
    perfumeOil: '',
    price: '',
    desc: '',
    tag: '',
    image: null,
    status: true
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const products = await getAllProduct();
        setProductList(products.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleFileChange = (e) => {
    setNewProduct({ ...newProduct, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);  // Clear previous form errors
    console.log(newProduct);
    const formData = new FormData();
    formData.append('authorId', newProduct.authorId);
    formData.append('bgColor', '#ffffff');
    formData.append('name', newProduct.name);
    formData.append('perfumeOil', newProduct.perfumeOil);
    formData.append('price', newProduct.price);
    formData.append('desc', newProduct.desc);
    formData.append('tag', newProduct.tag);
    formData.append('upload_image', newProduct.image); // Image file
    formData.append('status', newProduct.status);
    try {
      await axios.post('https://api.torin.store/api/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': '8282a7d8f5f20b95135f70706c744be38c1f5bdd'
        }
      });
      alert('Product created successfully');
      setNewProduct({ name: '', perfumeOil: '', price: '', desc: '', tag: '', image: null });
      // Re-fetch products after creating
      const products = await getAllProduct();
      setProductList(products.data);
    } catch (error) {
      console.error('Error creating product:', error.response);
      if (error.response && error.response.data) {
        setFormError(error.response.data);  // Set error from the server
      } else {
        setFormError('An unknown error occurred while creating the product.');
      }
    }
  };

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Perfume Admin</title>
        </Helmet>
        
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Admin</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>

        <Row className="mb-5">
          <Col lg="8" className="mb-5">
            {loading && <p>Loading products...</p>}
            {error && <Alert variant="danger">{error}</Alert>}
            {!loading && !error && (
              <Table striped bordered hover id='table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Perfume Oil</th>
                  <th>Price</th>
                  <th>Description</th>
                  <th>Tag</th>
                  <th>Image</th> {/* New column for Image */}
                </tr>
              </thead>
              <tbody>
                {productList.length > 0 ? (
                  productList.map((product, index) => (
                    <tr key={product.id}>
                      <td>{index + 1}</td>
                      <td>{product.name}</td>
                      <td>{product.perfumeOil}</td>
                      <td>${product.price}</td>
                      <td>{product.desc}</td>
                      <td>{product.tag}</td>
                      <td>
                        {/* Displaying the image */}
                        <img 
                          src={`https://api.torin.store/${product.image}`} // Adjust the base URL as needed
                          alt={product.name}
                          width="50" // Set a width for the image
                          height="50" // Set a height for the image
                          style={{ objectFit: 'cover' }} // Optional: to maintain aspect ratio
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No products found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            
            )}
          </Col>
        </Row>

        {/* Create Product Form */}
        <Row className="mb-5">
          <Col lg="8">
            <h2>Create New Product</h2>
            {formError && <Alert variant="danger">{formError}</Alert>}
            <Form onSubmit={handleSubmit}>
            <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control 
                type="text" 
                name="name" 
                value={newProduct.name} 
                onChange={handleInputChange} 
                placeholder="Enter product name" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="productPerfumeOil">
              <Form.Label>Perfume Oil</Form.Label>
              <Form.Control 
                type="text" 
                name="perfumeOil" 
                value={newProduct.perfumeOil} 
                onChange={handleInputChange} 
                placeholder="Enter perfume oil type" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="productPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control 
                type="number" 
                name="price" 
                value={newProduct.price} 
                onChange={handleInputChange} 
                placeholder="Enter product price" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="productDesc">
              <Form.Label>Description</Form.Label>
              <Form.Control 
                type="text" 
                name="desc" 
                value={newProduct.desc} 
                onChange={handleInputChange} 
                placeholder="Enter product description" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="productTag">
              <Form.Label>Tag</Form.Label>
              <Form.Control 
                type="text" 
                name="tag" 
                value={newProduct.tag} 
                onChange={handleInputChange} 
                placeholder="Enter product tag (e.g., New)" 
              />
            </Form.Group>

            <Form.Group controlId="productImage">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control 
                type="file" 
                onChange={handleFileChange} 
                required 
              />
            </Form.Group>
            {newProduct.image && (
              <div className="mt-3">
                <img 
                  src={URL.createObjectURL(newProduct.image)} 
                  alt="Product Preview" 
                  width="150" 
                  height="150" 
                  style={{ objectFit: 'cover' }} 
                />
              </div>
            )}

            {/* Status Field */}
            <Form.Group controlId="productStatus">
              <Form.Check
                type="checkbox" 
                name="status" 
                label="Active Status" 
                checked={newProduct.status} 
                onChange={(e) => setNewProduct({ ...newProduct, status: e.target.checked })} 
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Create Product
            </Button>
          </Form>

          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};

export default Admin;
