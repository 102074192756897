import homePhoto from "./assets/images/luwadlin-bosman-ejWLfAHa4y8-unsplash.jpg";
import seoImage from "./assets/images/r.jpg";
import p1 from "./assets/images/1.png";
import p2 from "./assets/images/2.png";
import p3 from "./assets/images/3.png";
import p4 from "./assets/images/4.png";
import p5 from "./assets/images/5.png";
import p6 from "./assets/images/6.png";
import p7 from "./assets/images/7.png";
const logotext = "TORIN";
const meta = {
    title: "Torin's Perfume",
    description: "Discover our exquisite perfumes, crafted with unique scents to captivate and inspire.",
    image: seoImage
};

const introdata = {
    title: "Torin's Perfume",
    animated: {
        first: "If you love someone, perfume gifts are a great choice",
        second: "បើអ្នកស្រឡាញ់នរណាម្នាក់កាដូទឹកអប់ជាជម្រើសដល់ល្អបំផុត",
    },
    description: "Discover our exquisite perfumes, crafted with unique scents to captivate and inspire. Elevate your senses with our signature fragrances.",
    your_img_url: homePhoto,
};

const dataabout = {
    title: "abit about my self",
    aboutme: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies.",
};
const worktimeline = [{
        jobtitle: "Designer of week",
        where: "YAdfi",
        date: "2020",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "Python",
        value: 90,
    },
    {
        name: "Djano",
        value: 85,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "React",
        value: 60,
    },
    {
        name: "Jquery",
        value: 85,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const dataPriceList = {
    man:[p1,p2,p3,p4],
    woman:[p5,p6,p7]
};
const contactConfig = {
    YOUR_EMAIL: "name@domain.com",
    YOUR_FONE: "095522008",
    YOUR_TELE: "https://t.me/Torin9",
    YOUR_FB: "https://www.facebook.com/share/fqBKo3mbKB8Bn24G/?mibextid=LQQJ4d",
    description: "We'd love to hear from you! Whether you have questions about our perfumes, need assistance with an order, or just want to share your fragrance experience, please get in touch with us. Our team is here to help and ensure you have the best possible experience with our products.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    facebook: "https://www.facebook.com/share/fqBKo3mbKB8Bn24G/?mibextid=LQQJ4d",
    tiktok: "https://www.tiktok.com/@torins.perfume?_t=8mgt6w0HwON&_r=1",
    telegram: "https://t.me/s/sothearin20/"
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
    dataPriceList
};